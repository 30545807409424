






import BaseHeader from "@/components/Base/BaseHeader.vue";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {
        BaseHeader,
    },
})
export default class SharedGalleryHeader extends Vue {
    @Prop() search!: string;

    get searchValue() {
        return this.search;
    }

    set searchValue(search: string) {
        this.updateSearch(search);
    }

    @Emit("search:update")
    updateSearch(search: string) {
        return search;
    }

    @Emit("gallery:add")
    addGallery() {
        return;
    }
}
